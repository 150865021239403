import React from "react";
import {
  IoOpenOutline,
  IoCheckmarkCircle,
  IoEye,
  IoLogIn,
  IoDocumentLock,
  IoPeople,
  IoSync,
  IoDocumentText,
  IoSunny,
  IoPaperPlane,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";

import { Button, Container, Wrapper, Row, Box } from "../../../components/util";
import {
  IntegrationsPageHeader,
  IframeContainer,
  IntegrationsNav,
} from "../../../components/site";
import Image from "../../../components/image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const PayHero = (props) => (
  <Layout>
    <Seo
      title="PayHero Integration | Karmly"
      description="NZ payroll for Recruitment. Connect Karmly with PayHero. Share candidate data, timesheets, leave and expenses between Karmly and your payroll software."
      pathname={props.location.pathname}
    />
    <IntegrationsNav />
    <IntegrationsPageHeader
      category="NZ PAYROLL FOR RECRUITMENT"
      title="Connect Karmly with PayHero"
      description="Share candidate data, timesheets, leave and expenses between Karmly and your payroll software."
    />
    <Container>
      <Wrapper>
        <Box className="-textCenter" stackGap={5}>
          <h2>Accurate, compliant, deeply integrated payroll and invoicing</h2>
          <h4>
            Payroll in Karmly just got a whole lot easier for New Zealand
            recruitment agencies.
          </h4>
        </Box>
        <Image
          filename="PayHero_Feature.png"
          alt="PayHero + Karmly Integration"
          addShadow
        />
      </Wrapper>
    </Container>
    <Container bg="lightGrey" className="-textCenter">
      <Wrapper>
        <Box stackGap={5}>
          <h2>Streamline your payroll and billing</h2>
          <h4>
            Single sign on two-way payroll integration for New Zealand
            recruitment agencies.
          </h4>
        </Box>
        <Box stackGap={60}>
          <Row stackGap={60}>
            <Box size={33}>
              <h3>
                <IoCheckmarkCircle />
                <br /> Your complete back office
              </h3>
              <h4>
                Everything you need to accurately invoice your clients and pay
                your candidates from the moment you make a placement.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoEye />
                <br /> Full visibility and control
              </h3>
              <h4>
                Deep two-way integration gives you total oversight and insight
                into every aspect of your payroll and invoicing.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoLogIn />
                <br /> Single sign on access
              </h3>
              <h4>
                Administrators can easily access and switch between systems
                without having to sign in again.
              </h4>
            </Box>
          </Row>
          <Row stackGap={60}>
            <Box size={33}>
              <h3>
                <IoDocumentLock />
                <br /> Take your compliance seriously
              </h3>
              <h4>
                PayHero stores annual leave in weeks and reviews recent work
                patterns to ensure you’re fully compliant with the New Zealand
                Holidays Act.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoPeople />
                <br />A beautiful candidate experience
              </h3>
              <h4>
                Your candidates can manage time, expenses, leave and pay through
                a single modern online portal and mobile app.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoSync />
                <br /> No more double entry
              </h3>
              <h4>
                When you place a candidate in a role their employee details will
                automatically flow through to payroll.
              </h4>
            </Box>
          </Row>
          <Row stackGap={60}>
            <Box size={33}>
              <h3>
                <IoDocumentText />
                <br /> Easily create and send payslips and BCIs
              </h3>
              <h4>
                Payslips and buyer created invoices generated in PayHero can be
                viewed and downloaded by your contractors and temps through the
                Karmly portal.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoSunny />
                <br /> Integrated leave management
              </h3>
              <h4>
                Temps and other non-contractors can view leave balances and
                request leave from inside Karmly.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoPaperPlane />
                <br /> Manage leave approvals, your way
              </h3>
              <h4>
                Set up leave approvals for temp workers by your payroll team or
                the consultants who placed them.
              </h4>
            </Box>
          </Row>
        </Box>
      </Wrapper>
    </Container>
    <Container className="ph-blue -textCenter">
      <Wrapper noPaddingBottom>
        <h2>
          PayHero is the perfect payroll for any size business
          <br className="hideOnMobile" /> with employees that work variable
          hours.
        </h2>
        <Box stackGap={60}>
          <Row stackGap={60}>
            <Box size={33}>
              <h3>
                <IoCheckmarkCircleOutline />
                <br /> Spend less time on payroll
              </h3>
              <h4>
                Pay your staff quickly and accurately and spend your valuable
                time growing your business and your staff.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoCheckmarkCircleOutline />
                <br /> Automate your Payday Filing
              </h3>
              <h4>
                Automatically send your payroll details to Inland Revenue after
                each pay.
              </h4>
            </Box>
            <Box size={33}>
              <h3>
                <IoCheckmarkCircleOutline />
                <br /> Leave management made easy
              </h3>
              <h4>
                All the info you and your employees need to request and approve
                leave. A calendar view makes it easy to see who’s away when.
              </h4>
            </Box>
          </Row>
          <Button
            className="blue -center -lg"
            to="https://www.payhero.co.nz/recruitment"
            atag
            externalLink
          >
            Payroll for Recruitment{" "}
            <IoOpenOutline css={{ top: "2px", marginLeft: "5px" }} />
          </Button>
        </Box>
        <Image
          filename="PayHero_Products_Cropped.png"
          alt="PayHero Payroll"
          maxWidth={1000}
          addShadow
          centerImage
        />
      </Wrapper>
    </Container>
    <Container>
      <Wrapper>
        <Box stackGap={40}>
          <h2 className="-textCenter">
            Connect Karmly with PayHero today,
            <br />
            it’s easy to get started.
          </h2>
          <Button className="primary -lg -center gtm-cta" to="/recruitment/signup">
            Get Started
          </Button>
        </Box>
      </Wrapper>
    </Container>
  </Layout>
);

export default PayHero;
